/*--------------------------------------------------------------
	home style 2
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#2.1	features-section
--------------------------------------------------------------*/
.features-section {
	position: relative;
	.feature-grids {
		background-color: $white;
		margin: -80px -15px 0;
		box-shadow: 0px 2px 18.9px 2.1px rgba(0, 0, 0, 0.04);

		@include media-query(991px) {
			margin: 90px -15px 0;
		}

		@include media-query(767px) {
			margin: 0 ;
			padding: 40px 20px 20px;
		}
	}

	.feature-grids .grid {
		width: 33.33%;
		float: left;
		margin: 0 0 0;
		padding: 120px 35px 55px;
		position: relative;

		@include media-query(991px) {
			width: 50%;
		}

		@include media-query(767px) {
			width: 100%;
			float: none;
			padding: 0 0 0 60px;
			margin-bottom: 20px;
		}
	}

	.feature-grids > .grid:nth-child(2) {
		background-color: #f5f5f5;

		@include media-query(767px) {
    		background: $white;
    	}
	}

	.grid .icon {
		position: absolute;
		left: 28px;
    	top: 35px;

    	@include media-query(767px) {
    		left: 0;
    		top: -8px;
    	}
	}

	.fi:before {
		font-size: 47px;
		font-size: calc-rem-value(47);
		color: $theme-primary-color;

		@include media-query(767px) {
			font-size: 40px;
			font-size: calc-rem-value(40);
		}
	}

	.grid h3 {
		font-size: 18px;
		font-size: calc-rem-value(18);
		margin: 0 0 0.7em;

		@include media-query(767px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
		}
	}

	.grid p {
		margin: 0;
	}
}


/*--------------------------------------------------------------
#2.2	about-section-s2
--------------------------------------------------------------*/
.about-section-s2 {
	@extend .about-section;
	background: #fafafb;
	padding-top: 145px;
	padding-bottom: 150px;

	@include media-query(1199px) {
		padding-bottom: 130px;
	}

	@include media-query(991px) {
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.img-holder {
		margin-left: 0;
		position: relative;

		img {
			position: relative;
		}

		&:before {
			content: "";
			@include background-style("../../images/about-s2-bg.jpg", center center, auto, no-repeat, local);
			width: 378px;
			height: 523px;
			position: absolute;
			right: -35px;
		    top: -35px;

		    @include media-query(1199px) {
		    	right: auto;
		    	left: -35px;
		    }

		    @include media-query(991px) {
		    	display: none;
		    }
		}
	}

	.img-holder button.btn-wrap {
		right: -37px;
		left: auto;

		@include media-query(991px) {
			right: auto;
			left: 50%;
			@include center-by-translating();
		}
	}

	@include widther(1200px) {
		.section-title-s2 {
			padding-top: 10px;
		}
	}
}

.about-section-s3 {
	padding-top: 5px;
}


/*--------------------------------------------------------------
#2.3	services-section-s2
--------------------------------------------------------------*/
.services-section-s2 {
	padding: 110px 0 80px;

	@include media-query(991px) {
		padding: 90px 0 60px;
	}

	@include media-query(767px) {
		padding: 80px 0 50px;
	}

	.services-grids .grid:hover {
		@include background-style("../../images/service-bg.jpg", center 0, auto, no-repeat, local);
	}

	.services-grids .grid {
		width: calc(33.33% - 30px);
		float: left;
		margin: 0 15px 30px;
		padding: 40px;
		border: 1px solid lighten($theme-primary-color, 30%);
		@include transition-time(0.5s);
		position: relative;

		@include media-query(991px) {
			width: calc(50% - 30px);
		}

		@include media-query(767px) {
			width: calc(100% - 30px);
			float: none;
			padding: 20px 25px;
		}


		&:before {
			content: "";
			background: transparentize($white, 0.03);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@include transition-time(0.2s);
		}
	}

	.grid .fi:before {
		font-size: 50px;
		font-size: calc-rem-value(50);
		color: $theme-primary-color;
	    @include transition-time(0.2s);

	    @include media-query(991px) {
			font-size: 35px;
			font-size: calc-rem-value(35);
		}
	}

	.services-grids .grid .icon,
	.services-grids .grid h3,
	.services-grids .grid p {
		position: relative;
	}

	.grid h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		font-weight: bold;
		margin: 0.7em 0 0.7em;

		@include media-query(991px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
		}
	}

	.grid h3 a {
		color: $heading-color;
		@include transition-time(0.2s);
	}

	.grid:hover .fi:before {
		color: $white;
	}

	.grid:hover h3 a,
	.grid:hover p {
		color: $white;
	}

	.grid p {
		margin: 0;
	}

	.grid:hover:before {
		background: transparentize($theme-primary-color, 0.03);
	}
}

.service-s2-pg-section {
	padding: 110px 0 80px;

	@include media-query(991px) {
		padding: 90px 0 60px;
	}

	@include media-query(767px) {
		padding: 80px 0 50px;
	}
}


/*--------------------------------------------------------------
#2.4	contact-section
--------------------------------------------------------------*/
.contact-section {
	margin-bottom: 110px;

	.section-title-s2 span:before {
		display: none;
	}

	.content-area {
		background-color: #f7f7f7;
		overflow: hidden;
		position: relative;

		.left-col,
		.right-col {
			width: 50%;

			@include media-query(991px) {
				width: 100%;
				float: none;
			}
		}

		.left-col {
			@include background-style("../../images/contact-bg.jpg", center center, cover, no-repeat, local);			
			float: left;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;

			@include media-query(991px) {
				float: none;
				position: relative;
				height: 400px;
			}
		}

		.right-col {
			float: right;
			padding: 100px 70px 55px;

			@include media-query(1199px) {
				padding: 100px 60px 55px;
			}

			@include media-query(991px) {
				float: none;
				padding: 90px 50px 45px;
			}

			@include media-query(767px) {
				padding: 80px 20px 35px;
			}
		}
	}

	.contact-message {
		background-color: transparentize($white, 0.1);
		max-width: 400px;
		padding: 25px;
		text-align: center;
		position: absolute;
		left: 50%;
		@include translatingX();
		bottom: 80px;

		@include media-query(767px) {
			width: 300px;
		}

		h4 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			line-height: 1.5em;

			@include media-query(767px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}
		}

		&:before {
			content: "";
			width: 0;
			height: 0;
			border-bottom: 25px solid transparentize($white, 0.1);
			border-left: 40px solid transparent;
			position: absolute;
			right: 25px;
			top: -25px;			
		}
	}

	.form-field {
		margin-bottom: 30px;
	}

	.comment-area {
		margin-bottom: 30px;
	}

	.errorMessage {
		color: red;
	}

	.contact-form input,
	.contact-form select,
	.contact-form textarea {
		background: #fdfdfd;
		width: 100%;
		height: 50px;
		font-size: 15px;
		font-size: calc-rem-value(15);
		border: 2px solid #f5f5f5;
		color: $white;
		border-radius: 0;
		padding: 6px 18px;
		box-shadow: none;

		&:focus {
			box-shadow: none;
			border-color: $theme-primary-color;
		}
	}

	.contact-form form {
		overflow: hidden;
		@include placeholder-style(#b0b0b0, 16px, normal);
	}

	.contact-form form > div:nth-child(3) {
		width: calc(100% - 30px);
	    float: none;
	    clear: both;
	}

	.contact-form form > .submit-area {
		margin-bottom: 0;
	}

	.contact-form form .comment-area {
		textarea {
			height: 170px;
		}

		textarea:focus {
			border-color: $theme-primary-color;
			outline: none;
		}
	}

}